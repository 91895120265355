const initialState = false;

const tableReducer = (state = initialState, action) => {
    switch (action.type) {
        case "HANDLE_CLEAR_INPUT": return !state;
        default: return state;
    }
}



export default tableReducer;
