import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Input, Label } from 'reactstrap'
import { BASE_URL, getUserData } from '../utils';
import LoadingBar from 'react-top-loading-bar';
import PartnerCreateForm from '../components/PartnerCreateForm';
import CreatePurchaseForm from '../components/CreatePurchaseForm';
import { object, string, number, date, InferType, phone } from 'yup';
import ModalAlert from '../components/ModalAlert';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const CreatePurchaseOrder = () => {

    const notifySuccess = (msg)=>toast.success(msg)
    const notifyFailed = (msg)=>toast.error(msg)
    const navigate = useNavigate();
    const ref = useRef(null);
    const [successMsg, setSuccessMsg] = useState(false)
    const login = getUserData();

    const [t,i18n] = useTranslation("create-purchase-order");




    const curLang = useSelector(state=>state.langReducer)
  
  
    useEffect(()=>{
      i18n.changeLanguage(curLang);
     },[curLang])

        var location = useLocation(),userData={
            id: '',
            name: '',
            email: '',
            contactPerson: '',
            btwNumber:'',
            phone:''
        };
        if(location.state && location.state.userData){
            userData = location.state.userData;
        }
        else{
            //alert();
            navigate('/partners/partners-list')
        }

    /*
    
    {
    "partnerId":"e9a34d75-7c11-4a12-88e0-0bed390d2f99",
    "consultantName":"Consultant 1",
    "startDate":"2023-07-01",
    "endDate":"2023-12-31",
    "location":"Netherlands",
    "rate":"500",
    "orderType":"DAILY",
    "currency":"EUR",
    "btwNumber":"21",
    "paymentTerm":"MONTHLY"
    }
    
    
    
    */

    let purchaseOrderSchema = object({
        name: string().required(),
        age: number().required().positive().integer(),
        email: string().email(),
        website: string().url().nullable(),
        createdOn: date().default(() => new Date()),
    });



    const formik = useFormik({
        initialValues: {
            partnerId: userData.partnerId,
            consultantName: '',
            startDate: '',
            endDate: '',
            location: '',
            rate: '',
            type: '',
            currency: '',
            btwPercentage: '',
            paymentTerm: '',
            partner:{...userData},
            projectType:''


        },
        validate: (values) => {

            const errors = {};

            // if (!values.partnerId) {
            //     errors.partnerId = 'This field is Required';
            // }

            if (!values.consultantName || values.consultantName.length < 0) {
                errors.consultantName = 'This field is Required';
            }

            if (!values.startDate) {
                errors.startDate = 'This field is Required';
            }

            if (!values.endDate) {
                errors.endDate = 'This field is Required';
            }

            if (!values.location) {
                errors.location = 'This field is Required';
            }

            // if (!values.rate) {
            //     errors.rate = 'This field is Required';
            // }
            if (!values.rate || !values.rate.match(/^\d*$/)) {
                errors.rate = 'Invalid Rate';
            }

            if (!values.type && values.projectType!="FE") {
                console.log(values.projectType)
                errors.type = 'This field is Required';
            }

            if (!values.currency) {
                errors.currency = 'This field is Required';
            }

            // if(!values.projectType){
            //     errors.projectType = 'This field is Required';

            // }

            // if (!values.btwPercentage) {
            //     errors.btwPercentage = 'This field is Required';
            // }
            
            if (!values.btwPercentage || !values.btwPercentage.match(/^\d*$/)) {
                errors.btwPercentage = 'Invalid BTW Number';
            }

            if (!values.paymentTerm) {
                errors.paymentTerm = 'This field is Required';
            }

            return errors;


        },
        onSubmit: values => {
            addPurchaseOrder();
        },
    });


    async function addPurchaseOrder() {

        try {
            if (ref.current)
                ref.current.continuousStart();
            let data = {};
            if (formik)
                data = { ...formik.values, };
                data.orderType=data.type;


                data.startDate = data.startDate.getFullYear() + "-" + (data.startDate.getMonth()<9?"0"+(data.startDate.getMonth() + 1):(data.startDate.getMonth() + 1)) + "-" + (data.startDate.getDate()<10?"0"+data.startDate.getDate():data.startDate.getDate());
                data.endDate = data.endDate.getFullYear() + "-" + (data.endDate.getMonth()<9?"0"+(data.endDate.getMonth() + 1):(data.endDate.getMonth() + 1)) + "-" + (data.endDate.getDate()<10?"0"+data.endDate.getDate():data.endDate.getDate());
                // data.orderType=data.type;
               // data.partnerId = "238996ca-318e-416f-9ff6-860b8ffa5285"//userData.partnerId;
            var res = await axios.post(BASE_URL + '/purchaseorders', { ...data },{
                headers: {
                  "Content-Type": "application/json",
                  "mode": "cors",
                  "Authorization": login.accessToken
                }
              });
            if (res.status == 201) {

               // alert("Created Successfully")
                setSuccessMsg(true);
               // navigate('/')
            }
        } catch (e) {
            notifyFailed("Failed")
        }
        if (ref.current)
            ref.current.complete()
    }




    return (
        <div className='create-partner-form-main'>
            <LoadingBar color='#3072bb' ref={ref} height={7} />
        
            <div className='create-partner-form'>
                <div className='form-heading-label'>
                    <h1><i className="fas fa-file-invoice" style={{ marginRight: "20px", }}></i>{t('header')}</h1>
                    <Link to="/"> <Button className="page-btn">{t('right-nav-btn')}  <i className='fas fa-eye'></i></Button> </Link>

                </div>
                <div className='form-body-row'>
                    <CreatePurchaseForm formik={formik} />
                </div>
                <div className='form-bottom'>
                    <Button className={"page-btn "+(Object.keys(formik.errors).length>0?'disabled-btn':'')} disabled={Object.keys(formik.errors).length>0} onClick={formik.handleSubmit}>Save +</Button>
                </div>
            </div>
            <ModalAlert open={successMsg} setOpen={setSuccessMsg} msgType={"Success"} onSuccess={() => navigate('/')} action="Ok" color="success">
                Purchase Order created Successfully!
            </ModalAlert>

            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}

export  {CreatePurchaseOrder}