import React from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

const ModalAlert = ({children,open,setOpen, onSuccess,msgType,action}) => {
    return (
        <Modal isOpen={open} className='alert-modal'>
            <ModalHeader> 
            {msgType?msgType:"Confirm Message"}
            {msgType!="Success" && <i onClick={()=>setOpen(false)} className='fas fa-times'></i>}
            </ModalHeader>
            <ModalBody>{children}</ModalBody>
            <ModalFooter>
                <Button id="alert-popup-apply-btn" onClick={()=>{setOpen(false);onSuccess();}} className='page-btn'>{action?action:"Delete"}</Button>
                {msgType!="Success" && <Button  onClick={()=>{setOpen(false);}} className='page-btn'>Cancel</Button>}

            </ModalFooter>
        </Modal>
    )
}

export default ModalAlert