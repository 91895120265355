import axios from 'axios'
import React, { useState, useEffect, useMemo } from 'react'
import { Menu } from 'react-feather'
import DatePicker from "react-datepicker";
import Select from 'react-select';

import "react-datepicker/dist/react-datepicker.css";import { Button, Col, Input, Label, Row } from 'reactstrap'
import { BASE_URL } from '../utils'
import Table from './Table'
import ModalAlert from './ModalAlert';
import { Link, useNavigate } from 'react-router-dom';

const InvoiceForm = ({ orderData, formik, setRateDetails, validateFile, translation }) => {


    const [openConfirmModal,setOpenModal] = useState(false);
    const navigate = useNavigate();
    // const [employees, setEmployees] = useState([]);

    // async function getEmployees() {
    //     try {
    //         var res = await axios.get(BASE_URL + '/invoice_purchase');
    //         if (res.status == 200) {
    //             setEmployees(res.data);
    //         }
    //     }
    //     catch (e) {

    //     }
    // }


    // useEffect(() => {
    //     getEmployees();
    // }, [])


    // const columns = useMemo(
    //     () => [
    //         {

    //             Header: "P.No",
    //             accessor: "p_no"
    //         },
    //         {
    //             Header: "Partner",
    //             accessor: "partner",

    //         },
    //         {
    //             Header: "Consultant",
    //             accessor: "consultant",

    //         },
    //         {
    //             Header: "Start Date",
    //             accessor: "start_date"
    //         },
    //         {
    //             Header: "End Date",
    //             accessor: "end_date"
    //         },
    //         {
    //             Header: "Location",
    //             accessor: "location"
    //         },
    //         {
    //             Header: "Rate",
    //             accessor: "rate"
    //         },
    //         {
    //             Header: "Type",
    //             accessor: "type"
    //         },
    //         {
    //             Header: "Currency",
    //             accessor: "currency"
    //         },
    //         {
    //             Header: "Vat Rate",
    //             accessor: "vat"
    //         },
    //         {
    //             Header: "Payment term",
    //             accessor: "payment_term"
    //         },
    //         {
    //             Header: "Routing",
    //             accessor: "routing"
    //         },

    //         {
    //             Header: "Action ",
    //             Cell: (row) => {

    //                 return (
    //                     <div style={{ display: "flex" }}>

    //                         <Button className='tbl-action-btn' color="btn btn-primary"><i className="fas fa-plus" style={{ margin: "0px 10px", cursor: "pointer", color: "#fff", fontSize: "10px" }}></i></Button>
    //                         <Button className='tbl-action-btn' color="btn btn-primary"><i className="fas fa-arrow-down" style={{ margin: "0px 10px", cursor: "pointer", color: "#fff", fontSize: "10px" }}></i></Button>
    //                     </div>
    //                 );
    //             }
    //         }
    //     ]
    //     ,
    //     []
    // );




  // console.log(formik)

    return (
        <div className='create-invoice-form-sect'>
            <div className='header'>
                <div className="tbl-title-header">
                    <h2><Menu size={20} /> {translation("header")}</h2>

                </div>
            </div>

            <form onSubmit={formik.handleSubmit}>
            <Row className='create-invoice-form'>
                <Col md="6">
                    <Row>
                        <Col md="6">
                            <Label>{translation("formField.0")}</Label> </Col>
                        <Col md="6">
                            <Input name="invoiceNumber" onChange={formik.handleChange} value={formik.values.invoiceNumber} onBlur={formik.handleBlur} /> <br />
                            {formik.errors.invoiceNumber && formik.touched.invoiceNumber && <span className='form-err-text'>This field is required</span>}
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <Label>{translation("formField.1")}</Label>
                        </Col>
                        <Col md="6">
                            <Input disabled value={formik.values.invoiceDate} onBlur={formik.handleBlur} /> <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <Label>{translation("formField.2")}</Label>
                        </Col>
                        <Col md="6">
                        <DatePicker className='date-input' onBlur={formik.handleBlur} selected={formik.values.startDate} onChange={(date) =>{ formik.setFieldValue("startDate",date)}} dateFormat="yyyy-MM-dd" />
                        </Col>
                    </Row >
                    <Row>

                        <Col md="6">

                            <Label>{translation("formField.3")}</Label>
                        </Col>
                        <Col md="6">

                        <DatePicker className='date-input' onBlur={formik.handleBlur} selected={formik.values.endDate} onChange={(date) => formik.setFieldValue("endDate",date)} dateFormat="yyyy-MM-dd"/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">

                            <Label>{translation("formField.4")}</Label>

                        </Col>
                        <Col md="6">

                            <Input type="number" name="quantity" onChange={e => setRateDetails(e.target.value)} onBlur={formik.handleBlur} /> <br />
                            {formik.errors.quantity && formik.touched.quantity && <span className='form-err-text'>This field is required</span>}                       

                        </Col>
                    </Row>
                    {/* {alert(orderData.projectType)} */}
                    {orderData.projectType!="FE"? <Row >

                        <Col md="6">

                            <Label>{translation("formField.5")}</Label>
                        </Col>

                        <Col md="6">

                            <Input disabled value={formik.values.rateType} onBlur={formik.handleBlur} /> <br />
                        </Col>
                    </Row>:null}
                    <Row>

                        <Col md="6">

                            <Label>{translation("formField.6")}</Label>
                        </Col>
                        <Col md="6">

                            <Input disabled value={formik.values.rate} onBlur={formik.handleBlur} /> <br />
                        </Col>
                    </Row>
                </Col >






                <Col md="6">
                    <Row>
                        <Col md="6">
                            <Label>{translation("formField.7")}</Label> </Col>
                        <Col md="6">
                            <Input disabled value={formik.values.amount} onBlur={formik.handleBlur} onChange={formik.handleChange} /> <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <Label>{translation("formField.8")}</Label>
                        </Col>
                        <Col md="6">
                            <Input disabled value={formik.values.btwAmount} onBlur={formik.handleBlur} onChange={formik.handleChange} /> <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <Label>{translation("formField.9")}</Label>
                        </Col>
                        <Col md="6">
                            <Input disabled value={formik.values.totalAmount} onBlur={formik.handleBlur} onChange={formik.handleChange} /> <br />
                        </Col>
                    </Row >
                    <Row>

                        <Col md="6">

                            <Label>{translation("formField.10")}</Label>
                        </Col>
                        <Col md="6">

                            <Input  name="docs" accept="application/pdf" onChange={(event)=>{if(validateFile(event.target,"document")){ formik.setFieldValue("document",event.target.files[0])}}} type="file" onBlur={formik.handleBlur} /> <br />
                            {formik.errors.document && formik.touched.document && <span className='form-err-text'>This field is required</span>}                       

                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">

                            <Label>{translation("formField.11")}</Label>
                        </Col>
                        <Col md="6">

                            <Input name="timesheets" accept="application/pdf"  onChange={(event)=>{if(validateFile(event.target,"timesheet")){ formik.setFieldValue("timesheet",event.target.files[0])}}} type="file" onBlur={formik.handleBlur} /> <br />
                            {formik.errors.timesheet && formik.touched.timesheet && <span className='form-err-text'>This field is required</span>}                       

                        </Col>
                    </Row>
                    <Row>

                        <Col md="6">

                            <Label>{translation("formField.12")}</Label>
                        </Col>

                        <Col md="6">

                            <Input name="approveEmail" accept="application/pdf"  onChange={(event)=>{if(validateFile(event.target,"approvalEmail")){ formik.setFieldValue("approvalEmail",event.target.files[0])}}} type="file" onBlur={formik.handleBlur} /> <br />
                            {formik.errors.approvalEmail && formik.touched.approvalEmail && <span className='form-err-text'>This field is required</span>}                       

                        </Col>
                    </Row>
                    <Row>

                        <Col md="6">

                            <Label>{translation("formField.13")}</Label>
                        </Col>
                        <Col md="6">

                            {/* <Input type='select'   onBlur={formik.handleBlur} /> <br /> */}
                            <Select  menuPosition="fixed" menuPlacement="auto"  options={[
                                {
                                 label:"CREATED",
                                 value:"CREATED"
                                },
                                 {
                                    label:"PENDING",
                                    value:"PENDING"
                                   }, {
                                    label:"ACCEPTED",
                                    value:"ACCEPTED"
                                   }
                            ]}
                            
                            onChange={(opt)=>{
                                formik.setFieldValue("status",opt.value)
                            }}
                            placeholder={formik.values.status}
                            
                            />
                        </Col>
                    </Row>

                </Col>
            </Row >
            
            <div className='create-invoice-form-bottom'>
                <Button color="btn btn-danger" onClick={()=>setOpenModal(true)}>Cancel</Button>
                <Button color="btn btn-primary" className={"page-btn "+(Object.keys(formik.errors).length>0?'disabled-btn':'')} disabled={Object.keys(formik.errors).length>0} onClick={formik.handleSubmit} type='submit'>Create Invoice</Button>
   
             <ModalAlert children={"Do you want to cancel"} msgType="Are you sure" open={openConfirmModal} setOpen={setOpenModal} action={"Confirm"}
             onSuccess={()=>navigate('/')}
             />

            </div>
            </form>
        </div >
    )
}

export default InvoiceForm