import React, { useRef } from 'react'
import { Col, Form, Row, Label, FormGroup, Input, Button } from 'reactstrap'
import LoginImg from '../assets/Login.svg'
import './page.css'
import { Link, useNavigate } from 'react-router-dom'
import logo from '../assets/callisto-logo.svg'
import { useFormik } from 'formik'
import axios from 'axios'
import { BASE_LOGIN_URL, BASE_URL, getUserData } from '../utils'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

import LoadingBar from 'react-top-loading-bar';
import { useDispatch } from 'react-redux'
import { assignUserDetails } from '../redux/actions/users'
import loginImg from '../assets/login-img.jpeg'


export const Login = () => {
  
  
  const notifyErr = () => toast.error("Login failed!");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ref = useRef(null);
  const login = getUserData()


  const formik = useFormik({
    initialValues: {
        // partnerId: userData.partnerId,
        userName:'',
       password:''


    },

    validate: (values) => {

        const errors = {};

        // if (!values.partnerId) {
        //     errors.partnerId = 'This field is Required';
        // }

        if (!values.userName) {
            errors.userName = 'This field is Required';
        }
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.userName)) {
          errors.userName = 'Invalid email address';
        }


        if (!values.password) {
            errors.password = 'This field is Required';
        }

        

        // if (!values.btwPercentage) {
        //     errors.btwPercentage = 'This field is Required';
        // }
        
  

        return errors;


    },
    onSubmit: values => {
        verifyLogin();
    },
});


   
   async function verifyLogin(){
 
    try{
      if(ref.current)
      ref.current.continuousStart();
      var res = await axios.post(BASE_LOGIN_URL+"/login",{
        ...formik.values
      },{
        headers: {
          "Content-Type": "application/json",
          // "mode": "cors",
        }
      })
      if(res.status==200){
        localStorage.setItem("loginDetails",JSON.stringify({...formik.values,...res.data,userName:formik.values.userName}))
        dispatch(assignUserDetails({...formik.values,...res.data}));
        if(res.data.role=="ADMIN")
        navigate(/*"/partners/partners-list"*/"/dashboard")
        else
        navigate("/")
      }
    }
    catch(e){
      console.log(e)
      notifyErr();
    }
    if(ref.current)
    ref.current.complete();
   }



  return (

    <Row className='login-form-main-parent' >
      <LoadingBar color='#3072bb' ref={ref} height={7} />

      <Col md="8" className='login-img-col'>
        <div className='inner-sect'>
          <img className='logo' src={/*"https://images.pexels.com/photos/546819/pexels-photo-546819.jpeg?auto=compress&cs=tinysrgb&w=600"*/loginImg}  />
        </div>
      </Col>
      <Col md="4" className='form-col' >

        <div className='login-form-container'>

          <Form className='form'>
            <FormGroup className="mb-5">
              <h2 for="exampleEmail" >
                
                <img className='logo' src={logo} width="220" height="160" />
           
                <p className='login-text' >Please login to your account.</p>
              </h2>
            </FormGroup>
            <FormGroup className="mb-5">
              <Label for="exampleEmail">Email</Label>
              <Input type="email" name="userName" id="exampleEmail" data-testid="eml" placeholder="Enter your E-mail" value={formik.values.userName} onChange={formik.handleChange} onBlur={formik.handleBlur} />
              {formik.errors.userName && formik.touched.userName ? <span className='form-err-text'>{formik.errors.userName}</span>:null}

            </FormGroup>
            <FormGroup className="mb-5">
              <Label for="examplePassword">Password</Label>
              <Input type="password" name="password" id="examplePassword" data-testid="pwd" placeholder="Enter your password" value={formik.values.password} onChange={formik.handleChange} onBlur={formik.handleBlur} />
              {formik.errors.password && formik.touched.password ? <span className='form-err-text'>{formik.errors.password}</span>:null}

            </FormGroup>
            <FormGroup className='bottom-sect'>
              <div>
                <Input type="checkbox" />
                <span className='remember-me-txt'><>Remember me</></span>
              </div>
              <div>
                <Link to="/auth/forgot-password"><span>Forgot your password?</span></Link>

              </div>

            </FormGroup>
            <FormGroup>
               <Button onClick={formik.handleSubmit} color='btn btn-primary' className='submit-btn w-100'  >Submit</Button>
            </FormGroup>
          </Form>

        </div>


      </Col>
<ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
    </Row>

  )
}

