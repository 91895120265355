import { combineReducers } from "redux";
import sidebarCollapseReducer,{sidebarCollapseReducerMobile} from './sidebar'
import userReducer from "./users";
import tableReducer from "./Table";
import langReducer from './lang'
const reducers = combineReducers(
    {
    //   myNumber:changeTheNumber
    sidebarCollapseReducer,
    sidebarCollapseReducerMobile,
    userReducer,
    tableReducer,
    langReducer
    }
);

export default reducers;