import React, { useEffect, useState } from 'react'
import { Search } from 'react-feather';
import { useSelector } from 'react-redux';
import { useAsyncDebounce } from 'react-table'
import { Input } from 'reactstrap';

export const GlobalFilter = ({ filter, setGlobalFilter }) => {

  const [filterInput, setFilterInput] = useState("");

  const resetInput = useSelector(state=>state.tableReducer);


  useEffect(()=>{
  //alert("running useeffect for clear input")
   setFilterInput("")
   setGlobalFilter("")
  },[resetInput])


  // Update the state when input changes
  const handleFilterChange = e => {
    const value = e.target.value || undefined;
    setFilterInput(value);
    setGlobalFilter(e.target.value)
  };


  return (
  

    <div className='search-comp-sect' >
      <Input value={filterInput} placeholder='Search...' onChange={handleFilterChange} />
      <Search size={40} className='global-filter-search-btn'  />
    </div>
  )
}