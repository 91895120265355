import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Button, Card, Input } from 'reactstrap'
import { Tooltip } from 'reactstrap';

import './page.css'
import Table from '../components/Table';
import SearchBar from '../components/SearchBar';
import { Plus } from 'react-feather';
import axios from 'axios';
import { BASE_URL, getUserData, logout } from '../utils';
import RouteLabel from '../components/RouteLabel';
import { Link, useNavigate } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
import LoadingComponent from '../components/LoadingComponent';
import ErrorComponent from '../components/ErrorComponent';
import NotFound from '../components/NotFound';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import ModalAlert from '../components/ModalAlert';
import { useDispatch, useSelector } from 'react-redux';
import { handleClearInput } from '../redux/actions/Table';
import { useTranslation } from 'react-i18next';
import TableDetailsModal from '../components/TableDetailsModal';




export const Home = () => {


  const [t,i18n] = useTranslation("purchase-order");

  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [err, setErr] = useState(false);
  const [retry, setRetry] = useState(false)

  const navigate = useNavigate();
  const ref = useRef(null);
  const [loading, setLoading] = useState(false);
  const [currentPurchaseOrder,setCurrentPurchaseOrder] = useState(false)
  const [mobileColumn,setMobileColumn] = useState(false)
  const [openDetailsModal,setOpenDetailsModal] = useState(false);

  const [openAlert,setOpenAlert] = useState(false)
  const dispatch = useDispatch();
   
  const userData = getUserData();
  const login = userData
  
  const notifyDel = () => toast.success("Deleted Successfully");
  const notifyErr = () => toast.error("Failed to delete Purchase Order!");

  const curLang = useSelector(state=>state.langReducer)



  const searchParams = new URLSearchParams(document.location.search)



  if(searchParams.get("partnerType")==null){
    if(userData.role=="ADMIN")
     navigate("/dashboard")
  }


  useEffect(()=>{
   i18n.changeLanguage(curLang);
  },[curLang])
  






  useEffect(()=>{

    
      if(window.innerWidth<=500)
        setMobileColumn([
          {
            Header: t('table.0'),//"Partner",
            accessor: "partner.name",
    
          },
          {
            Header: t('table.10'),//"Action ",
            Cell: (row) => {
              return (
                <div className='tbl-action-col'>
                {row.partner.type}
    
                  {userData && userData.role=="ADMIN"?<RenderBtn id={"edit"+row.row.id} tooltip="Edit" className='tbl-action-btn' color="btn btn-primary" icon={<i className="fas fa-edit" ></i>} onClick={() => { navigate("/partners/edit-purchase-order", { state: { orderData: { ...row.row.original } } }); }}></RenderBtn>:null}
                  {userData && userData.role!=="ADMIN" ?<RenderBtn id={"add"+row.row.id} tooltip="Create Invoice" className='tbl-action-btn' color="btn btn-primary" icon={<i className="fas fa-plus" ></i>} onClick={() => { navigate("/invoices/create-invoice", { state: { orderData: { ...row.row.original } } }); }}></RenderBtn>:null}
                  {/* <RenderBtn id={"down"+row.row.id} tooltip="Download" className='tbl-action-btn' color="btn btn-primary" icon={<i className="fas fa-arrow-down" ></i>} ></RenderBtn> */}
                  {userData && userData.role=="ADMIN"?<RenderBtn id={"del"+row.row.id} tooltip={"Delete"} className='tbl-action-btn' color="btn btn-primary" icon={<i className="fas fa-trash" ></i>} onClick={() => { setCurrentPurchaseOrder(row.row.original); setOpenAlert(true); /* deletePurchaseOrder(row.row.original) /*navigate("/session-timed-out");*/ }}></RenderBtn>:null}
                  <RenderBtn id={"view"+row.row.id} tooltip={"View Details"} className='tbl-action-btn' color="btn btn-primary" icon={<i className="fas fa-ellipsis-v" ></i>} onClick={() => { setCurrentPurchaseOrder(row.row.original); setOpenDetailsModal(true); /* deletePurchaseOrder(row.row.original) /*navigate("/session-timed-out");*/ }}></RenderBtn>
                  
                </div>
              );
            }
          }
        ]
        )


   var winEvent = window.addEventListener('resize', function(event) { 
      const currentScreenWidth = event.target.innerWidth;
      if(currentScreenWidth<=500){
        setMobileColumn([
          {
            Header: t('table.0'),//"Partner",
            accessor: "partner.name",
    
          },
          {
            Header: t('table.10'),//"Action ",
            Cell: (row) => {
              return (
                <div className='tbl-action-col'>
    
    
                  {userData && userData.role=="ADMIN"?<RenderBtn id={"edit"+row.row.id} tooltip="Edit" className='tbl-action-btn' color="btn btn-primary" icon={<i className="fas fa-edit" ></i>} onClick={() => { navigate("/partners/edit-purchase-order", { state: { orderData: { ...row.row.original } } }); }}></RenderBtn>:null}
                  {userData && userData.role!="ADMIN"?<RenderBtn id={"add"+row.row.id} tooltip="Create Invoice" className='tbl-action-btn' color="btn btn-primary" icon={<i className="fas fa-plus" ></i>} onClick={() => { navigate("/invoices/create-invoice", { state: { orderData: { ...row.row.original } } }); }}></RenderBtn>:null}
                  {/* <RenderBtn id={"down"+row.row.id} tooltip="Download" className='tbl-action-btn' color="btn btn-primary" icon={<i className="fas fa-arrow-down" ></i>} ></RenderBtn> */}
                  {userData && userData.role=="ADMIN"?<RenderBtn id={"del"+row.row.id} tooltip={"Delete"} className='tbl-action-btn' color="btn btn-primary" icon={<i className="fas fa-trash" ></i>} onClick={() => { setCurrentPurchaseOrder(row.row.original); setOpenAlert(true); /* deletePurchaseOrder(row.row.original) /*navigate("/session-timed-out");*/ }}></RenderBtn>:null}
                  <RenderBtn id={"view"+row.row.id} tooltip={"View Details"} className='tbl-action-btn' color="btn btn-primary" icon={<i className="fas fa-ellipsis-v" ></i>} onClick={() => { setCurrentPurchaseOrder(row.row.original); setOpenDetailsModal(true); /* deletePurchaseOrder(row.row.original) /*navigate("/session-timed-out");*/ }}></RenderBtn>
                  
                </div>
              );
            }
          }
        ]
        )
      }else{
        setMobileColumn(false)
      }
     })

    return ()=>window.removeEventListener('resize',winEvent)

  },[])
  /*{
    "orders": [
        {
            "purchaseOrderNumber": "2a674f74-7074-409e-9e7c-4c95598e51fe",
            "consultantName": "Consultant 1",
            "startDate": "2023-07-01",
            "endDate": "2023-12-31",
            "location": "Netherlands",
            "rate": 500,
            "orderType": "DAILY",
            "currency": "EUR",
            "btwNumber": 21,
            "paymentTerm": "MONTHLY"
        }
    ]
}*/

  async function getPurchaseOrders() {
    try {
      if (ref.current)
        ref.current.continuousStart()
      setLoading(true)

      var res = await axios.get(BASE_URL + '/purchaseorders?partnerType='+searchParams.get("partnerType"),{
        headers: {
          "Content-Type": "application/json",
          "mode": "cors",
          "Authorization": login.accessToken
        }
      });
      if (res.status == 200) {

        setPurchaseOrders(res.data.orders);
        setErr(false)
      }
    }
    catch (e) {
      setErr(true)
      logout();
      localStorage.setItem("loginDetails",null)
      window.location.reload()
            
    }
    if (ref.current)
      ref.current.complete()
    setLoading(false)

  }


  useEffect(() => {
    if (err)
      setErr(!err)
    getPurchaseOrders();
  }, [retry])

  const columns =   [
      {
        Header: t('table.0'),//"Partner",
        accessor: "partner.name",

      },
      {
        Header: t('table.1'),//"Consultant",
        accessor: "consultantName",

      },
      {
        Header: t('table.2'),//"Start Date",
        accessor: "startDate"
      },
      {
        Header: t('table.3'),//"End Date",
        accessor: "endDate"
      },
      {
        Header: t('table.4'),//"Location",
        accessor: "location"
      },
      {
        Header: t('table.5'),//"Rate",
        accessor: "rate"
      },
      {
        Header: t('table.6'),//"Type",
        accessor: "orderType"
      },
      {
        Header: t('table.7'),//"Currency",
        accessor: "currency"
      },
      
      {
        Header: t('table.8'),//"Vat Rate",
        accessor: "btwPercentage"
      },
      {
        Header: t('table.9'),//"Payment Term",
        accessor: "paymentTerm"
      },



      {
        Header: t('table.10'),//"Action ",
        Cell: (row) => {
          return (
            <div className='tbl-action-col'>


              {userData && userData.role=="ADMIN"?<RenderBtn id={"edit"+row.row.id} tooltip="Edit" className='tbl-action-btn' color="btn btn-primary" icon={<i className="fas fa-edit" ></i>} onClick={() => { navigate("/partners/edit-purchase-order", { state: { orderData: { ...row.row.original } } }); }}></RenderBtn>:null}
              {(userData && (userData.role!="ADMIN" || row.data[row.row.id].partner.type === "Client"))?<RenderBtn id={"add"+row.row.id} tooltip="Create Invoice" className='tbl-action-btn' color="btn btn-primary" icon={<i className="fas fa-plus" ></i>} onClick={() => { navigate("/invoices/create-invoice?partnerType="+searchParams.get("partnerType"), { state: { orderData: { ...row.row.original } } }); }}></RenderBtn>:null}
              {/* <RenderBtn id={"down"+row.row.id} tooltip="Download" className='tbl-action-btn' color="btn btn-primary" icon={<i className="fas fa-arrow-down" ></i>} ></RenderBtn> */}
              {userData && userData.role=="ADMIN"?<RenderBtn id={"del"+row.row.id} tooltip={"Delete"} className='tbl-action-btn' color="btn btn-primary" icon={<i className="fas fa-trash" ></i>} onClick={() => { setCurrentPurchaseOrder(row.row.original); setOpenAlert(true); /* deletePurchaseOrder(row.row.original) /*navigate("/session-timed-out");*/ }}></RenderBtn>:null}

            </div>
          );
        }
      }
    ]
  //   ,
  //   [curLang]
  // );


  async function deletePurchaseOrder(purchaseOrder){
    if (ref.current)
      ref.current.continuousStart()
      try{
         var res = await axios.delete(BASE_URL+"/purchaseorders/"+purchaseOrder.purchaseOrderNumber,{
          headers: {
            "Content-Type": "application/json",
            "mode": "cors",
            "Authorization": login.accessToken
          }
        });
         if(res.status==200 || res.status==204){
          notifyDel();
          getPurchaseOrders();
          dispatch(handleClearInput(''));

         }
      }
      catch(e){
          notifyErr();
      }
      if (ref.current)
        ref.current.complete()
  }

  function RenderBtn({ className, onClick, icon, tooltip, id, children }) {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    return (
      <>

        <Button id={id} className={className} color="btn btn-primary" onClick={onClick}>{icon}</Button>
        <Tooltip
                    isOpen={tooltipOpen}
                    toggle={toggle}
                    placement="right" target={id} /*toggle={this.toggle}*/>
                    {tooltip}
                </Tooltip>
           </>
    )
  }


  return (
    <div>
      <LoadingBar color='#3072bb' ref={ref} height={7} />
      {purchaseOrders!=null && purchaseOrders.length > 0 ? <div>      <RouteLabel />
        <h2 className='page-title'> {t("header")}</h2>
        <Table data={purchaseOrders} columns={mobileColumn?mobileColumn:columns} title={t("header")} />
      </div> : err ? <ErrorComponent retry={retry} setRetry={setRetry} /> : loading ? <LoadingComponent /> : <NotFound msg={"No orders found!"} />}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
            <ModalAlert open={openAlert} setOpen={setOpenAlert} onSuccess={()=>{if(currentPurchaseOrder){deletePurchaseOrder(currentPurchaseOrder);setCurrentPurchaseOrder(false)}}}>Are you sure to delete this purchase order</ModalAlert>
            <TableDetailsModal isOpen={openDetailsModal} setIsOpen={setOpenDetailsModal} data={currentPurchaseOrder}  />

    </div>

  )
}

export default Home