import React, { useState } from 'react'
import './css/components.css';
import { Button, Label } from 'reactstrap';

const InvProductsListTbl = ({ formik }) => {

    const [invProducts,setInvProducts] = useState([{}]);
    function delInvProduct(index){
       var arr = [...invProducts];
       arr.splice(index,1);
       setInvProducts([...arr])
    }
    
    return (
        <div className='invoice-products-list-tbl-main'>

            <div className='invoice-products-list-tbl-header'>
                <Label>Item</Label>
                <Label>quantity</Label>
                <Label>rate</Label>
                <Label className='amt-label'>amount</Label>
            </div>
            {invProducts && invProducts.map((val,index)=><div className='invoice-products-list-tbl-rowdata'>
                <input type='text' value={val.desc} placeholder='Description of item/service'></input>
                <div className='amt-wrap'>
                    <input type="number" value={val.price} placeholder='0'></input>
                    <i className='fas fa-times qty-icn' />
                    <input type="text" value={val.qty} placeholder='10%'></input>
                </div>
                <span className='amt-label'>$0.00</span>
                <span className='del-btn' onClick={()=>delInvProduct(index)}><span className='msg'>Delete </span><i class="bi bi-trash-fill"></i></span>
            </div>)}
            {/* <div className='invoice-products-list-tbl-rowdata'>
                <input type='text' placeholder='Description of item/service'></input>
                <div className='amt-wrap'>
                    <input type="numbet" placeholder='0'></input>
                    <i className='fas fa-times qty-icn' />
                    <input type="text" placeholder='10%'></input>
                </div>

                <span className='amt-label'>$0.00</span>
            </div>
            <div className='invoice-products-list-tbl-rowdata'>
                <input type='text' placeholder='Description of item/service'></input>
                <div className='amt-wrap'>
                    <input type="numbet" placeholder='0'></input>
                    <i className='fas fa-times qty-icn' />
                    <input type="text" placeholder='10%'></input>
                </div>
                <span className='amt-label'>$0.00</span>
            </div>
            <div className='invoice-products-list-tbl-rowdata'>
                <input type='text' placeholder='Description of item/service'></input>
                <div className='amt-wrap'>
                    <input type="numbet" placeholder='0'></input>
                    <i className='fas fa-times qty-icn' />
                    <input type="text" placeholder='10%'></input>
                </div>

                <span className='amt-label'>$0.00</span>
            </div> */}

            <Button className={"page-btn add-inv-product-row-btn"} onClick={()=>setInvProducts([...invProducts,{}])}>Line Item <i class="bi bi-plus-lg"></i></Button>


        </div>
    )
}

export default InvProductsListTbl