import React, { useState } from 'react'
import { X } from 'react-feather'
import { Button, Input, Modal,ModalBody,ModalFooter,ModalHeader, } from 'reactstrap'
const CommentsModal = ({isOpen,setIsOpen,addRemarks,currentRemarks}) => {
    const [currentInput,setInp] = useState("");
    var monthLabels = [
        { label: "January", value: "01" }, { label: "Febuary", value: "02" },
        { label: "March", value: "03" }, { label: "April", value: "04" },
        { label: "May", value: "05" }, { label: "June", value: "06" },
        { label: "July", value: "07" }, { label: "August", value: "08" },
        { label: "September", value: "09" }, { label: "October", value: "10" },
        { label: "November", value: "11" }, { label: "December", value: "12" },
    ];

    console.log(currentRemarks)
    return (
        <Modal id="commentModal" isOpen={isOpen}>
        <ModalHeader className='comment-modal-header'>All Remarks <X style={{marginLeft:"auto"}} onClick={()=>setIsOpen(!isOpen)} /></ModalHeader>
        <ModalBody className='comment-popup-body-main'>
        <div>
            {currentRemarks && currentRemarks.length>0?currentRemarks.map(val=>{
                // return  <div className="comment-row" >
                // <div className="comment-sub-row" >
                //  <i className='fas fa-comments' ></i>
                //  <div className='comment-body'>
                //     {val.remarks}
               // </div>
               //             </div>

                // </div>
                return <div className="comment-row" >
                <div className="comment-sub-row" >
                 <i className='fas fa-comments' ></i>
                <div className='comment-heading'>{val.addedBy?val.addedBy:"N/A"}<span> on {val.addedOn?(new Date(val.addedOn).getDate()+"th "+monthLabels[new Date(val.addedOn).getMonth()].label+" " + new Date(val.addedOn).getFullYear() ):"N/A"}</span>
                </div>
                </div>
                <div className='comment-body'>
                    {val.remarks}
                </div>
            </div>
                {/* <div className='comment-heading'>Maria Smantha - <span>2 hours ago</span>
                </div> */}
                {/* <div className='comment-body'>
                    {val.remarks}

                </div> */}
            }): <div className='loading-page-main' style={{height:"281px"}}>
            <h1 id="no-remarks-msg">No Remarks Found...</h1>
          </div>}
            {/* <div className="comment-row" >
                <div className="comment-sub-row" >
                 <i className='fas fa-comments' ></i>
                <div className='comment-heading'>Maria Smantha - <span>2 hours ago</span>
                </div>
                </div>
                <div className='comment-body'>
                    It is a long established fact that a reader will be distracted by the readable content of a page.

                </div>
            </div>
            <div className="comment-row" >
                <div className="comment-sub-row" >
                 <i className='fas fa-comments' ></i>
                <div className='comment-heading'>Maria Smantha - <span>2 hours ago</span>
                </div>
                </div>
                <div className='comment-body'>
                    It is a long established fact that a reader will be distracted by the readable content of a page.

                </div>
            </div>
            <div className="comment-row" >
                <div className="comment-sub-row" >
                 <i className='fas fa-comments' ></i>
                <div className='comment-heading'>Maria Smantha - <span>2 hours ago</span>
                </div>
                </div>
                <div className='comment-body'>
                    It is a long established fact that a reader will be distracted by the readable content of a page.

                </div>
            </div>
            <div className="comment-row" >
                <div className="comment-sub-row" >
                 <i className='fas fa-comments' ></i>
                <div className='comment-heading'>Maria Smantha - <span>2 hours ago</span>
                </div>
                </div>
                <div className='comment-body'>
                    It is a long established fact that a reader will be distracted by the readable content of a page.

                </div>
            </div> */}
        </div>
        </ModalBody>
        <ModalFooter className="comment-modal-footer">
            <Input value={currentInput} className='comment-msg-area' type='textarea' rows="7" placeholder="Add new Comment..." onChange={(e)=>setInp(e.target.value)} />

            <Button className="page-btn" onClick={()=>{addRemarks(currentInput);setInp('')}}>Add Remark +</Button>
        </ModalFooter>
        </Modal>

    )
}

export default CommentsModal