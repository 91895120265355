import React from 'react'

const NotFound = ({msg,children}) => {
  return (
    <div className='loading-page-main' style={{flexDirection:"column",gap:"10px"}}>
      <h1>{msg?msg:"No Data Found..."}</h1>
      {children && <div>{children}</div>}
    </div>
  )
}

export default NotFound