export const ChartLayoutComp = (data,borderColor,borderWidth)=>{ 
 // alert("")
    return {
    labels: data.map((data) => data.status),
    datasets: [
      {
        label: "No. of Invoices",
        data: data.map((data) => data.invCount),
        backgroundColor:['#ddf009cf','#191f64','#ff4100cf'],//getColorCodes(data),
        borderColor: borderColor?borderColor:"#ededed",
        borderWidth: borderWidth?borderWidth:1,
      },
    ],
  }
}


export const BarChartLayoutComp = (data,borderColor,borderWidth)=>{ 
  // alert("")
     return {
     labels: data.map((data) => data.status),
     datasets: [
       {
         label: "Invoices Amount",
         data: data.map((data) => data.amount),
         backgroundColor:['#ddf009cf','#191f64','#ff4100cf'],//getColorCodes(data),
         borderColor: borderColor?borderColor:"#ededed",
         borderWidth: borderWidth?borderWidth:1,
       },
     ],
   }
 }


function getColorCodes(data){
    var colors=[];
    data.map(val=>{
        colors.push(getRandomColor(val.invCount));
    })
    return colors
}

function getRandomColor(code) {
    var colorLetters = '0123456789ABCDEF';
    var colorCode = '#';
    for (var i = 0; i < 6; i++) {
        colorCode += colorLetters[Math.floor(1 * 16%code)];
    }
    return colorCode;
  }




  export const ChartLayoutCompMonths = (data,dateObj)=>{ 
    return {
    labels: data.map((data) => data.year),
    datasets: [
      {
        label: "Users Gained",
        data: data.map((data) => data.userGain),
        backgroundColor:getColorCodes(data),
        borderColor: "#ededed",
        borderWidth: 1,
      },
    ],
  }
}

