import axios from "axios";

export const BASE_URL="https://api.zakelijkfacturen.nl/im-api/invoice-management"
export const BASE_LOGIN_URL="https://api.zakelijkfacturen.nl/im-api/invoice-management"
export const testingUrl=/*"https://ras360.nl"*/"http://localhost:3000"
//"http://localhost:8000" //"https://ras360.nl/im-api/invoice-management"

//http://185.107.90.252:8086/im-api/invoice-management

export const getUserData = ()=>{
    var login = localStorage.getItem("loginDetails");
    if(login){
      login = JSON.parse(login);
    }
    else{
      login={}
    }
    return login;
    
}





export const logout = async ()=>{

  const login = getUserData();
  var res = await axios.delete(BASE_URL+"/logout",{
    headers: {
      "Content-Type": "application/json",
      "mode": "cors",
      "Authorization": login.accessToken
    }
  });
  if(res.status==200){
     return true;
  }
  return false;
}




export function validateZipCode(zipCode){
   const zipcodeChar = zipCode.split("");
   let nums=0,letters=0;
   zipcodeChar.map(char=>{
      if(isNumber(char))
        nums++;
      else if(isLetter(char))
        letters++;
    

   })
    
   if(nums==4 && letters==2)
     return true;
   return false;
}

function isNumber(char) {
    return /^\d$/.test(char);
}



function isLetter(char) {
  return /^[a-zA-Z]$/.test(char);
}


export const countryLabels = [{
  label:'Netherlands',
  value:'Netherlands'
}]

export const currencyLabels = [{
  label: 'EUR',
  value: 'EUR'
}, {
  label: 'DOLLAR',
  value: 'DOLLAR'
}, {
  label: 'INR',
  value: 'INR'
}, {
  label: 'Swedish Krona',
  value: 'Swedish Krona'
}]