import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";

function BarChart({ chartData }) {
  return <Bar data={chartData} options={{maintainAspectRatio: false
   ,responsive:true,barPercentage: 0.6,categoryPercentage:1 , scales: {
    xAxes: [{
        categoryPercentage: 1,
        barPercentage: 1
    }]
}}} />;
}

export default BarChart;