import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Input, Label } from 'reactstrap'
import Select from 'react-select'
import { countryLabels } from '../utils';
import './css/PartnerForm.css';

const PartnerCreateForm = ({ formik }) => {


    const [t, i18n] = useTranslation("create-partner");




    const curLang = useSelector(state => state.langReducer)


    useEffect(() => {
        i18n.changeLanguage(curLang);
    }, [curLang])



    function validateFormCustom() {
        // formik.touched.name = true;
        // formik.touched.phone = true;
        // formik.touched.alias = true;
        // formik.touched.contactPerson = true;
        // formik.touched.btwNumber = true;
        // formik.touched.email = true
        // formik.touched.houseNumber = true;
        // formik.touched.postalCode = true;
        // formik.touched.streetName = true;
        // formik.touched.country = true;
        formik.setFieldTouched("name", true)
        formik.setFieldTouched("phone", true)
        formik.setFieldTouched("alias", true)
        formik.setFieldTouched("contactPerson", true)
        formik.setFieldTouched("btwNumber", true)
        formik.setFieldTouched("email", true)
        formik.setFieldTouched("houseNumber", true)
        formik.setFieldTouched("postalCode", true)
        formik.setFieldTouched("streetName", true)
        formik.setFieldTouched("country", true)


    }

    if (formik)
        return (
            <div className='form-body partner-form'>
                <div className='form-horizontal'>
                    <h3 className='form-grp-lbl'>{t('form.0.header')}:</h3>
                    <div className='inp-rows cols-3'>
                        <div>
                            <Label>{t('form.0.labels.0')}:</Label><br />
                            <div className="cp-name">
                                <i className='fas fa-user'></i>
                                <Input type="text" placeholder={t('form.0.labels.0')} name='name' value={formik.values.name} onChange={formik.handleChange} onBlur={(e) => { formik.handleBlur(e); validateFormCustom() }} /><br />
                            </div>
                            {formik.errors.name && formik.touched.name ? <span className='form-err-text'>{formik.errors.name}</span> : null}

                        </div>
                        <div>
                            <Label>{t('form.0.labels.1')}:</Label><br />
                            <div className="cp-phone">
                                <i className='fas fa-phone'></i>
                                <Input type="text" placeholder={t('form.0.labels.1')} name='phone' value={formik.values.phone} onChange={formik.handleChange} onBlur={(e) => { formik.handleBlur(e); validateFormCustom() }} /><br />

                            </div>
                            {formik.errors.phone && formik.touched.phone ? <span className='form-err-text'>{formik.errors.phone}</span> : null}

                        </div>


                        <div>
                            <Label>{t('form.0.labels.2')}:</Label><br />
                            <div className="cp-email">
                                <i className="fas fa-id-badge"></i>
                                <Input type="text" placeholder={t('form.0.labels.2')} name='alias' value={formik.values.alias} onChange={formik.handleChange} onBlur={(e) => { formik.handleBlur(e); validateFormCustom() }} /><br />

                            </div>
                            {formik.errors.alias && formik.touched.alias ? <span className='form-err-text'>{formik.errors.alias}</span> : null}

                        </div>

                    </div>
                    <div className='inp-rows cols-33'>
                        <div>
                            <Label>{t('form.0.labels.3')}:</Label><br />
                            <div className="cp-contact">
                                <i className='fas fa-users'></i>
                                <Input type="text" placeholder={t('form.0.labels.3')} name='contactPerson' value={formik.values.contactPerson} onChange={formik.handleChange} onBlur={(e) => { formik.handleBlur(e); validateFormCustom() }} /><br />

                            </div>
                            {formik.errors.contactPerson && formik.touched.contactPerson ? <span className='form-err-text'>{formik.errors.contactPerson}</span> : null}

                        </div>
                        <div>
                            <Label>{t('form.0.labels.4')}</Label><br />
                            <div className="cp-id">
                                <i className='fas fa-file'></i>
                                <Input type="text" placeholder={t('form.0.labels.4')} name='btwNumber' value={formik.values.btwNumber} onChange={formik.handleChange} onBlur={(e) => { formik.handleBlur(e); validateFormCustom() }} /><br />

                            </div>
                            {formik.errors.btwNumber && formik.touched.btwNumber ? <span className='form-err-text'>{formik.errors.btwNumber}</span> : null}

                        </div>

                        <div>
                            <Label>{t('form.0.labels.5')}:</Label><br />
                            <div className="cp-email">
                                <i className='fas fa-envelope'></i>
                                <Input type="email" placeholder={t('form.0.labels.5')} name='email' value={formik.values.email} onChange={formik.handleChange} onBlur={(e) => { formik.handleBlur(e); validateFormCustom() }} /><br />

                            </div>
                            {formik.errors.email && formik.touched.email ? <span className='form-err-text'>{formik.errors.email}</span> : null}

                        </div>
                        {/* <div>
    <Label>Name:</Label><br/>
    <Input type="text" placeholder="Name.." />
</div> */}


                        {console.log(t('form.0'), {})}
                    </div>

                </div>

                <div className='form-horizontal'>
                    <h3 className='form-grp-lbl' style={{ marginTop: "22px", marginBottom: "30px" }}>{t('form.0.header2')}:</h3>


                    <div className='inp-rows '>






                        <div>
                            <Label>{t('form.0.labels.6')}:</Label><br />
                            <div className="cp-email">
                                <i className='fas fa-location-arrow'></i>
                                <Input type="text" placeholder={t('form.0.labels.6')} name='houseNumber' value={formik.values.houseNumber} onChange={formik.handleChange} onBlur={(e) => { formik.handleBlur(e); validateFormCustom() }} /><br />

                            </div>
                            {formik.errors.houseNumber && formik.touched.houseNumber ? <span className='form-err-text'>{formik.errors.houseNumber}</span> : null}

                        </div>

                        <div>
                            <Label>{t('form.0.labels.7')}:</Label><br />
                            <div className="cp-email">
                                <i className="fas fa-map-pin"></i>
                                <Input type="text" placeholder={t('form.0.labels.7')} name='postalCode' value={formik.values.postalCode} onChange={formik.handleChange} onBlur={(e) => { formik.handleBlur(e); validateFormCustom() }} /><br />

                            </div>
                            {formik.errors.postalCode && formik.touched.postalCode ? <span className='form-err-text'>{formik.errors.postalCode}</span> : null}

                        </div>
                    </div>


                    <div className='inp-rows'>
                        <div>
                            <Label>{t('form.0.labels.8')}:</Label><br />
                            <div className="cp-email">

                                <i className="fas fa-road"></i>
                                <Input type="text" placeholder={t('form.0.labels.8')} name='streetName' value={formik.values.streetName} onChange={formik.handleChange} onBlur={(e) => { formik.handleBlur(e); validateFormCustom() }} /><br />

                            </div>
                            {formik.errors.streetName && formik.touched.streetName ? <span className='form-err-text'>{formik.errors.streetName}</span> : null}

                        </div>






                        <div>
                            <Label>{t('form.0.labels.9')}:</Label><br />
                            <div className="cp-email">
                                <i className='fas fa-location-arrow'></i>
                                {/* <Input type="text" placeholder={t('form.0.labels.9')} name='country' value={formik.values.country} onChange={formik.handleChange} onBlur={(e) => { formik.handleBlur(e); validateFormCustom() }} /><br />

                        </div> */}
                                <Select menuPosition="fixed" menuPlacement="auto" id="payment-term-drpdwn" className='form-drpdwn' options={[...countryLabels]}
                                    onBlur={(e) => { validateFormCustom(); formik.handleBlur(e) }}
                                    onChange={(opt) => {
                                        formik.setFieldValue("country", opt.value)
                                    }}
                                    placeholder={formik.values.country ? formik.values.country : t('form.0.labels.9')} />

                            </div>
                            {/* {formik.errors.paymentTerm && formik.touched.paymentTerm ? <span className='form-err-text'>{formik.errors.paymentTerm}</span> : null} */}

                            {formik.errors.country && formik.touched.country ? <span className='form-err-text'>{formik.errors.country}</span> : null}

                        </div>
                    </div>

                    {/* <div className='inp-rows'> */}
                        {formik.values.country == "Netherlands" && <div>
                            <Label>{t('form.0.labels.10')}:</Label><br />
                            <div className="cp-email">

                                <i className="fas fa-file-alt"></i>
                                <Input type="text" placeholder={t('form.0.labels.10')}  /><br />

                            </div>
                            {/* {formik.errors.streetName && formik.touched.streetName ? <span className='form-err-text'>{formik.errors.streetName}</span> : null} */}

                        </div>}

                      
                      
                    {/* </div> */}

                    




                    {/* <div className='inp-rows'>


  
    </div> */}
                </div>

            </div>
        )
    else return null;
}

export default PartnerCreateForm