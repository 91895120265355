import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Alert, Button, Input, Label } from 'reactstrap'
import { BASE_URL, getUserData, validateZipCode } from '../utils';
import LoadingBar from 'react-top-loading-bar';
import PartnerCreateForm from '../components/PartnerCreateForm';
import { object, string, number, date, InferType, phone } from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalAlert from '../components/ModalAlert';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import GenerateInvoiceForm from '../components/GenerateInvoiceForm';

const GenerateInvoice = () => {

    const notifySuccess = () => toast.success("Partner Created Successfully");
    const notifyErr = () => toast.error("Failed to create partner!");
    const navigate = useNavigate();
    const [successMsg, setSuccessMsg] = useState(false)
    const login = getUserData();
    var ref = useRef(null);

    const [t,i18n] = useTranslation("create-partner");




    const curLang = useSelector(state=>state.langReducer)
  
  
    const formik = useFormik({

    })


    



    return (
        <div className='create-partner-form-main'>
            <LoadingBar color='#3072bb' ref={ref} height={7} />

            <ModalAlert open={successMsg} setOpen={setSuccessMsg} msgType={"Success"} onSuccess={() => navigate('/partners/partners-list')} action="Ok" color="success">
                Invoice template created Successfully!
            </ModalAlert>

            <div className='create-partner-form invoice-generate-form-main'>
                <div className='form-heading-label'>
                    <h1><i className="fas fa-user-plus" style={{ marginRight: "20px", }}></i>{"Generate Invoice"}</h1>
                    {/* <Link to="/partners/partners-list"> <Button className="page-btn">{}<i className='fas fa-eye'></i></Button> </Link> */}

                </div>
                <div className='form-body-row'>


                    <GenerateInvoiceForm />
                </div>
                <div className='form-bottom'>
                    <Button className={"page-btn " + (Object.keys(formik.errors).length > 0 ? 'disabled-btn' : '')} disabled={Object.keys(formik.errors).length > 0} onClick={formik.handleSubmit}>Save</Button>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}

export { GenerateInvoice }