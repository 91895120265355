import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';
import { Button, Input, Label } from 'reactstrap'
import DatePicker from "react-datepicker";
import Select from 'react-select';

import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { currencyLabels } from '../utils';

const CreatePurchaseForm = ({ formik,type }) => {

    const [t,i18n] = useTranslation("create-purchase-order");




    const curLang = useSelector(state=>state.langReducer)
  
  
    useEffect(()=>{
      i18n.changeLanguage(curLang);
     },[curLang])

     
    function validateFormCustom() {
        // formik.touched.name = true;
        // formik.touched.phone = true;
        // formik.touched.alias = true;
        // formik.touched.contactPerson = true;
        // formik.touched.btwNumber = true;
        // formik.touched.email = true
        // formik.touched.houseNumber = true;
        // formik.touched.postalCode = true;
        // formik.touched.streetName = true;
        // formik.touched.country = true;
        formik.setFieldTouched("consultantName",true)
        formik.setFieldTouched("paymentTerm",true)
        formik.setFieldTouched("location",true)
        formik.setFieldTouched("startDate",true)
        formik.setFieldTouched("endDate",true)
        formik.setFieldTouched("type",true)
        formik.setFieldTouched("rate",true)
        formik.setFieldTouched("currency",true)
        formik.setFieldTouched("btwPercentage",true)
        // formik.setFieldTouched("country",true)


    }

    if (formik) {
        var maxDate = new Date(formik.values.endDate)
        maxDate.setDate(maxDate.getDate() - 1);
        var minDate = new Date(formik.values.startDate)
        minDate.setDate(minDate.getDate() + 1);


        


        return (
            <div className='form-body'>
                <div className='inp-rows'>
                    <div>
                        <Label>{t('form.0.labels.0')}:</Label><br />
                        <div className="cp-row">
                            <i className='fas fa-user'></i>
                            <Input disabled={type=="edit"} type="text" placeholder={t('form.0.labels.0')} name='consultantName' value={formik.values.consultantName} onChange={formik.handleChange} onBlur={(e)=>{validateFormCustom();formik.handleBlur(e)}} /><br />
                        </div>

                        {formik.errors.consultantName && formik.touched.consultantName ? <span className='form-err-text'>{formik.errors.consultantName}</span> : null}

                    </div>
                    {/* <div>
            <Label>Partner ID:</Label><br />
            <div className="cp-row">
                <i className='fas fa-user'></i>
                <Input type="text" placeholder="Partner ID.." name='partnerId' value={formik.values.partnerId} onChange={formik.handleChange} onBlur={(e)=>{validateFormCustom();formik.handleBlur(e)}} /><br/>

            </div>
            {formik.errors.partnerId && formik.touched.partnerId ? <span className='form-err-text'>{formik.errors.partnerId}</span>:null}

        </div> */}
                    <div>
                        <Label>{t('form.0.labels.1')}:</Label><br />
                        <div className="cp-row">
                            <i className='far fa-credit-card'></i>
                            {/* <Input type="email" placeholder="Payment Term.." name='paymentTerm' value={formik.values.paymentTerm} onChange={formik.handleChange} onBlur={(e)=>{validateFormCustom();formik.handleBlur(e)}} /><br/> */}
                            <Select  menuPosition="fixed" menuPlacement="auto" isDisabled={type=="edit"} id="payment-term-drpdwn" className='form-drpdwn' options={[
                                {
                                    label: "DAILY",
                                    value: "DAILY"
                                },
                                {
                                    label: "MONTHLY",
                                    value: "MONTHLY"
                                },


                            ]}
                                onBlur={(e)=>{validateFormCustom();formik.handleBlur(e)}}
                                onChange={(opt) => {
                                    formik.setFieldValue("paymentTerm", opt.value)
                                }}
                                placeholder={formik.values.paymentTerm} />

                        </div>
                        {formik.errors.paymentTerm && formik.touched.paymentTerm ? <span className='form-err-text'>{formik.errors.paymentTerm}</span> : null}

                    </div>

                </div>
                <div className='inp-rows cols-3'>


                    <div>
                        <Label>{t('form.0.labels.2')}:</Label><br />
                        <div className="cp-row">
                            <i className='fas fa-location-arrow'></i>
                            <Input disabled={type=="edit"} type="email" placeholder={t('form.0.labels.2')} name='location' value={formik.values.location} onChange={formik.handleChange} onBlur={(e)=>{validateFormCustom();formik.handleBlur(e)}} /><br />

                        </div>
                        {formik.errors.location && formik.touched.location ? <span className='form-err-text'>{formik.errors.location}</span> : null}

                    </div>






                    <div>
                        <Label>{t('form.0.labels.3')}:</Label><br />
                        <div className="cp-row">
                            <i className='fas fa-calendar-week'></i>
                            <DatePicker id="startdate" maxDate={maxDate} className='date-input' onBlur={(e)=>{validateFormCustom();formik.handleBlur(e)}} selected={formik.values.startDate} onChange={(date) => { formik.setFieldValue("startDate", date) }} dateFormat="yyyy-MM-dd" />

                        </div>
                        {formik.errors.startDate && formik.touched.startDate ? <span className='form-err-text'>{formik.errors.startDate}</span> : null}

                    </div>
                    <div>
                        <Label>{t('form.0.labels.4')}:</Label><br />
                        <div className="cp-row">
                            <i className='fas fa-calendar-week'></i>
                            <DatePicker id="enddate" minDate={minDate} className='date-input' onBlur={(e)=>{validateFormCustom();formik.handleBlur(e)}} selected={formik.values.endDate} onChange={(date) => formik.setFieldValue("endDate", date)} dateFormat="yyyy-MM-dd" />

                        </div>
                        {formik.errors.endDate && formik.touched.endDate ? <span className='form-err-text'>{formik.errors.endDate}</span> : null}

                    </div>


                </div>


               <div className='inp-rows cols-33'>
                  <div>
                        <Label>Project Type:</Label><br />
                        <div className="cp-row">
                            <i className='fas fa-users'></i>
                            {/* <Input type="text" placeholder="Order Type.." name='orderType' value={formik.values.orderType} onChange={formik.handleChange} onBlur={(e)=>{validateFormCustom();formik.handleBlur(e)}} /><br/> */}
                            <Select  menuPosition="fixed" menuPlacement="auto" id="project-type-drpdwn" isDisabled={type=="edit"} className='form-drpdwn' options={[
                                {
                                    label: "Fixed Price",
                                    value: "FP"
                                },
                                {
                                    label: "Time & Material",
                                    value: "TM"
                                },



                            ]}
                                onBlur={(e)=>{validateFormCustom();formik.handleBlur(e)}}
                                onChange={(opt) => {
                                    formik.setFieldValue("projectType", opt.value)
                                }}

                                placeholder={formik.values.projectType == '' ? "Project Type..." : formik.values.projectType} />
                        </div>
                        {formik.errors.projectType && formik.touched.projectType ? <span className='form-err-text'>{formik.errors.projectType}</span> : null}

                    </div>
                    
                    {!(formik.values.projectType=="FP") &&  <div>
                        <Label>{t('form.0.labels.5')}:</Label><br />
                        <div className="cp-row">
                            <i className='fas fa-users'></i>
                            {/* <Input type="text" placeholder="Order Type.." name='orderType' value={formik.values.orderType} onChange={formik.handleChange} onBlur={(e)=>{validateFormCustom();formik.handleBlur(e)}} /><br/> */}
                            <Select  menuPosition="fixed" menuPlacement="auto" id="rate-type-drpdwn" isDisabled={type=="edit"} className='form-drpdwn' options={[
                                {
                                    label: "HOURLY",
                                    value: "HOURLY"
                                },
                                {
                                    label: "DAILY",
                                    value: "DAILY"
                                },



                            ]}
                                onBlur={(e)=>{validateFormCustom();formik.handleBlur(e)}}
                                onChange={(opt) => {
                                    formik.setFieldValue("type", opt.value)
                                }}

                                placeholder={formik.values.type == '' ?t('form.0.labels.5') : formik.values.type} />
                        </div>
                        {formik.errors.type && formik.touched.type ? <span className='form-err-text'>{formik.errors.type}</span> : null}

                    </div>}
                    <div>
                        <Label>{t('form.0.labels.6')}:</Label><br />
                        <div className="cp-row">
                            <i className={/*'fas fa-euro-sign'*/'fas fa-chart-line'}></i>
                            <Input type="text" placeholder="Rate.." name='rate' value={formik.values.rate} onChange={formik.handleChange} onBlur={(e)=>{validateFormCustom();formik.handleBlur(e)}} /><br />

                        </div>
                        {formik.errors.rate && formik.touched.rate ? <span className='form-err-text'>{formik.errors.rate}</span> : null}

                    </div>


          


                </div>
                {/* <div>
    <Label>Name:</Label><br/>
    <Input type="text" placeholder="Name.." />
</div> */}




                <div className='inp-rows cols-3'>
                    <div>
                        <Label>{t('form.0.labels.7')}:</Label><br />
                        <div className="cp-row">
                            <i className='fas fa-money-bill-wave'></i>
                            {/* <Input disabled={type=="edit"} type="email" placeholder={t('form.0.labels.7')} name='currency' value={formik.values.currency} onChange={formik.handleChange} onBlur={(e)=>{validateFormCustom();formik.handleBlur(e)}} /><br /> */}
                            <Select  menuPosition="fixed" menuPlacement="auto" isDisabled={type=="edit"} id="payment-term-drpdwn" className='form-drpdwn' options={[
                                ...currencyLabels
                            ]}
                                onBlur={(e)=>{validateFormCustom();formik.handleBlur(e)}}
                                onChange={(opt) => {
                                    formik.setFieldValue("currency", opt.value)
                                }}
                                placeholder={formik.values.currency?formik.values.currency:t('form.0.labels.7')} />

                        </div>
                        {formik.errors.currency && formik.touched.currency ? <span className='form-err-text'>{formik.errors.currency}</span> : null}

                    </div>
                    <div>
                        <Label>{t('form.0.labels.8')}:</Label><br />
                        <div className="cp-row">
                            <i className='fas fa-percentage'></i>
                            <Input disabled={type=="edit"} type="text" placeholder={t('form.0.labels.8')} name='btwPercentage' value={formik.values.btwPercentage} onChange={formik.handleChange} onBlur={(e)=>{validateFormCustom();formik.handleBlur(e)}} /><br />

                        </div>
                        {formik.errors.btwPercentage && formik.touched.btwPercentage ? <span className='form-err-text'>{formik.errors.btwPercentage}</span> : null}

                    </div>

                </div>

            </div>
        )
    }
    else return null;
}

export default CreatePurchaseForm