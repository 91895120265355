import React, { useState } from "react";
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import './css/components.css'
import { ChevronDown, ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight, Menu } from "react-feather";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { GlobalFilter } from "./GlobalFilter";
import { useSelector } from "react-redux";
// import '../css/components.css'
export default function Table({ columns, data, title ,style , className}) {
  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    page,
    previousPage,
    nextPage,
    gotoPage,
    pageCount,
    setPageSize,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,

    setFilter,
    setGlobalFilter,

    // Adding the useFilters Hook to the table
    // rows for the table based on the data passed
    prepareRow // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable({
    columns,
    data,
    initialState: { pageIndex: 0 }
  }, useGlobalFilter,
    useSortBy,
    usePagination);

  const pageSizeArr = [10, 20, 30, 40]
  const { pageIndex, pageSize } = state






  // Input element


  /* 
    Render the UI for your table
     - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
  */
  return (
    <div className={"tbl-main-parent " + ((className)?className:"")} >
      <GlobalFilter filter={null} setGlobalFilter={setGlobalFilter} />
      <UncontrolledDropdown>
        <DropdownToggle className="page-size-dropdown">Page Size: <span><b>{pageSize}</b></span> <ChevronDown size={20} style={{ marginLeft: "auto" }} /></DropdownToggle>
        <DropdownMenu>
          {pageSizeArr.map(val => {

            return <DropdownItem onClick={() => setPageSize(val)}>{val}</DropdownItem>

          })}


        </DropdownMenu>
      </UncontrolledDropdown>
      <div className="tbl-title-header">
        <h2><Menu size={20} /> {title} </h2>

      </div>
      <div style={{ overflow: "auto" }}>
        <table className="tbl1" style={style} {...getTableProps()} >


          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  // <th className="employee-tbl-header" {...column.getHeaderProps()}>{column.render("Header")} <i className="fas fa-sort"></i> </th>
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={
                      (column.isSorted
                        ? column.isSortedDesc
                          ? "sort-desc"
                          : "sort-asc"
                        : "") + " employee-tbl-header"
                    }
                  >
                    {column.render("Header")} {(column.isSorted
                        ? column.isSortedDesc
                          ? <i className="fas fa-sort-down"></i>
                          : <i className="fas fa-sort-up"></i>
                        : <i className="fas fa-sort"></i>)}  
                  </th>
                ))}

              </tr>

            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr style={{ boxShadow: "0px 0px 9px 0px rgba(0, 0, 0, 0.1)" }} {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td className="employee-td" {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="table-paginate">
        {pageIndex > 0 ? <div>
          <button className="paginate-btn" id="page-prev-first" onClick={() => gotoPage(0)}><ChevronsLeft size={20} /></button>
          <button className="paginate-btn" id="page-prev" onClick={() => previousPage()}><ChevronLeft size={20} /></button>
        </div> : <div></div>}
        <span style={{marginTop:"40px"}}><b style={{ marginRight: "20px", fontSize: "18px", fontWeight: "normal" }}>Page:</b><span style={{ marginRight: "20px",color:"#314bbc",fontWeight:"800"}}>{pageIndex + 1}</span> <span style={{ marginRight: "20px",}}>of</span> <span id="total-page-val">{pageCount}</span></span>
        {pageIndex < pageCount - 1 ? <div>
          <button className="paginate-btn" id="page-next" onClick={() => nextPage()}><ChevronRight size={20} /></button>
          <button className="paginate-btn" id="page-next-end" onClick={() => gotoPage(pageCount - 1)}><ChevronsRight size={20} /></button>

        </div> : <div></div>}
      </div>
    </div>
  );
}