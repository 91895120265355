import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Alert, Button, Input, Label } from 'reactstrap'
import { BASE_URL, getUserData, validateZipCode } from '../utils';
import LoadingBar from 'react-top-loading-bar';
import PartnerCreateForm from '../components/PartnerCreateForm';
import { object, string, number, date, InferType, phone } from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalAlert from '../components/ModalAlert';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const CreateClient = () => {

    const notifySuccess = () => toast.success("Partner Created Successfully");
    const notifyErr = () => toast.error("Failed to create partner!");
    const navigate = useNavigate();
    const [successMsg, setSuccessMsg] = useState(false)
    const login = getUserData();
    



    const [t,i18n] = useTranslation("create-partner");




    const curLang = useSelector(state=>state.langReducer)
  
  
    useEffect(()=>{
      i18n.changeLanguage(curLang);
     },[curLang])


    // let partnerSchema = object({
    //     name: string().required(),
    //     btwNumber: number().required().positive().integer(),
    //     email: string().email(),
    //     phone: string().phone().required(),
    //     contactPerson:string().required()
    //   });



    //console.log(orderData)

    const ref = useRef(null);

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            contactPerson: '',
            btwNumber: '',
            phone: '',
            country: '',
            streetName: '',
            houseNumber: '',
            postalCode: '',
            alias: '',
        },
        validate: (values) => {

            const errors = {};

            if (!values.name || values.name.length <= 0) {
                errors.name = 'This field is Required';
            }


            if (!values.phone) {
                errors.phone = 'This field is Required';
            }

            else if (!(/^\d{10}$/i).test(values.phone)) {
                // alert("not")
                errors.phone = 'Invalid Phone Number';

            }


            if (!values.contactPerson) {
                errors.contactPerson = 'This field is Required';
            }

            if (!values.btwNumber) {
                errors.btwNumber = 'This field is Required';
            }
            if (!values.country) {
                errors.country = 'This field is Required';
            }
            if (!values.postalCode) {
                errors.postalCode = 'This field is Required';
            }
            else if (!(/^\d{4}[A-Z]{2}$/i).test(values.postalCode)) {
                // alert("not")
                errors.postalCode = 'Invalid Postal Code';

            }
            if (!values.houseNumber) {
                errors.houseNumber = 'This field is Required';
            }
            else if (!(/\d+/i).test(values.houseNumber)) {
                // alert("not")
                errors.houseNumber = 'Invalid House Number';

            }
            if (!values.streetName) {
                errors.streetName = 'This field is Required';
            }
            if (!values.alias) {
                errors.alias = 'This field is Required';
            }

            if (!values.email) {
                errors.email = ' This field is Required ';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                errors.email = 'Invalid email address';
            }

            return errors;


        },
        onSubmit: (values) => {
            // alert();
            addPartner();
        },
    });


    async function addPartner() {

        try {
            if (ref.current)
                ref.current.continuousStart();
            let data = {};
            if (formik)
                data = { ...formik.values };
            data.phoneNumber = data.phone;
            data.postcode = data.postalCode
            // data.btwNumber = parseInt(data.btwNumber)

            //  alert("")
            var res = await axios.post(BASE_URL + '/clients', { ...data },{
                headers: {
                  "Content-Type": "application/json",
                  "mode": "cors",
                  "Authorization": login.accessToken
                }
              });
            if (res.status == 201) {
                // alert("Created Successfully")
                setSuccessMsg(true);

               // setTimeout(() => navigate('/partners/partners-list'), 10000)
            }
        } catch (e) {
            notifyErr();
        }
        if (ref.current)
            ref.current.complete()
    }



    return (
        <div className='create-partner-form-main'>
            <LoadingBar color='#3072bb' ref={ref} height={7} />

            <ModalAlert open={successMsg} setOpen={setSuccessMsg} msgType={"Success"} onSuccess={() => navigate('/partners/partners-list')} action="Ok" color="success">
                Partner created Successfully!
            </ModalAlert>

            <div className='create-partner-form'>
                <div className='form-heading-label'>
                    <h1><i className="fas fa-user-plus" style={{ marginRight: "20px", }}></i>{t('header')}</h1>
                    <Link to="/partners/partners-list"> <Button className="page-btn">{t('right-nav-btn')}<i className='fas fa-eye'></i></Button> </Link>

                </div>
                <div className='form-body-row'>


                    <PartnerCreateForm formik={formik} />
                </div>
                <div className='form-bottom'>
                    <Button className={"page-btn " + (Object.keys(formik.errors).length > 0 ? 'disabled-btn' : '')} disabled={Object.keys(formik.errors).length > 0} onClick={formik.handleSubmit}>Save</Button>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}

export { CreateClient }