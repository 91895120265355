import React, { useState } from 'react'
import RoutesMain from '../routes'
import { Card, Col, Row } from 'reactstrap'
import './layout.css';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import { useDispatch, useSelector } from 'react-redux';
import { sidebarCollapseReducerMobile } from '../redux/reducers/sidebar';

const Layout1 = () => {

  var isSidebarCollapsed = useSelector(state => state.sidebarCollapseReducer)
  var isSidebarCollapsedMobile = useSelector(state => state.sidebarCollapseReducerMobile)
  return (
    <div>
      <Row className='dashboard-layout-main'>
        <Col className={'sidebar-main z-1 ' + (isSidebarCollapsedMobile?"s-left-0":"s-left-300")} md="3" style={isSidebarCollapsed ? { marginLeft: "-220px", transition: "0.3s ease" } : { transition: "0.3s ease" }}>
          <Sidebar />
        </Col>
        <Col className='page-col' md="9" >
          <Navbar />
          <div className='admin-page-parent'>

            <RoutesMain />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Layout1