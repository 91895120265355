

export const handleClearInput = (val) => {
    return {
        type: 'HANDLE_CLEAR_INPUT',
        val: val
    }
}




