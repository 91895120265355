import React from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'

const TableDetailsModal = ({ isOpen, setIsOpen, data }) => {
    return (
        <Modal isOpen={isOpen} className="table-details-modal">
            <ModalHeader>
                <span style={{fontSize:"20px"}}>Details Overview</span>
                <Button onClick={()=>setIsOpen(false)} style={{marginLeft:"auto",float:"right"}} className='action-btn'>
                    <i className='fas fa-times'></i>
                </Button>
            </ModalHeader>

            <ModalBody>
                <div className='table-details-main'>
                    {data && Object.keys(data).map(val => {
                        if(typeof data[val]=='object')
                            return null;
                        return (
                            <div className='table-details-row'>
                                <div><b>{val}</b></div>
                                <div>{data[val]}</div>
                            </div>
                        )
                    })}

                    {/* <div className='table-details-row'></div>
        <div className='table-details-row'></div>
        <div className='table-details-row'></div>
        <div className='table-details-row'></div>
        <div className='table-details-row'></div> */}

                </div>
            </ModalBody>
        </Modal>
    )
}

export default TableDetailsModal