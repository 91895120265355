import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Alert, Button, Input, Label } from 'reactstrap'
import { BASE_URL, getUserData, validateZipCode } from '../utils';
import LoadingBar from 'react-top-loading-bar';
import PartnerCreateForm from '../components/PartnerCreateForm';
import RouteLabel from '../components/RouteLabel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalAlert from '../components/ModalAlert';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const EditPartner = () => {
    const ref = useRef(null);

    const notifySuccess = () => toast.success("Edited Successfully");
    const notifyErr = () => toast.error("Failed to edit partner!");
    const navigate = useNavigate();
    const [successMsg, setSuccessMsg] = useState(false)
    const login = getUserData();



    const [t,i18n] = useTranslation("create-partner");




    const curLang = useSelector(state=>state.langReducer)
  
  
    useEffect(()=>{
      i18n.changeLanguage(curLang);
     },[curLang])



    var location = useLocation(), userData = {
        name: '',
        email: '',
        contactPerson: '',
        btwNumber: '',
        phone: '',
        streetName: 'abcd colonyu'
    };
    if (location.state && location.state.userData) {
        userData = location.state.userData;
    }
    else{
        navigate('/partners/partners-list');
     }
  
    const formik = useFormik({
        initialValues: {
            name: userData.name,
            email: userData.email,
            contactPerson: userData.contactPerson,
            btwNumber: userData.btwNumber,
            phone: userData.phone,
            streetName: userData.streetName,
            houseNumber: userData.houseNumber,
            alias: userData.alias,
            country: userData.country,
            postalCode: userData.city//postalCode,

        },
        validate: (values) => {


            const errors = {};

            if (!values.name || values.name.length <= 0) {
                errors.name = 'This field is Required';
            }


            if (!values.phone) {
                errors.phone = 'This field is Required';
            }
            else if (!values.phone.match(/^\d{10}$/)) {
                errors.phone = 'Invalid Phone Number';

            }


            if (!values.contactPerson) {
                errors.contactPerson = 'This field is Required';
            }


            if (!values.btwNumber) {
                errors.btwNumber = 'This field is Required';
            }


            if (!values.email) {
                errors.email = ' This field is Required ';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                errors.email = 'Invalid email address';
            }

            if (!values.country) {
                errors.country = 'This field is Required';
            }
            if (!values.postalCode) {
                errors.postalCode = 'This field is Required';
            }
            else if (!(/^\d{4}[A-Z]{2}$/i).test(values.postalCode)) {
                // alert("not")
                errors.postalCode = 'Invalid Postal Code';

            }
            if (!values.houseNumber) {
                errors.houseNumber = 'This field is Required';
            }
            else if (!(/^\d+$/i).test(values.houseNumber)) {
                // alert("not")
                errors.houseNumber = 'Invalid House Number';

            }
            if (!values.streetName) {
                errors.streetName = 'This field is Required';
            }
            if (!values.alias) {
                errors.alias = 'This field is Required';
            }


            return errors




        },
        onSubmit: values => {
            editPartner()

        },
    });


    useEffect(() => {
        if (userData.name == '')
            navigate('/partners/partners-list')
    }, [])

    async function editPartner() {

        try {
            if (ref.current)
                ref.current.continuousStart();
            let data = {};
            if (formik)
                data = { ...formik.values };
            data.phoneNumber = data.phone;
            data.postcode = data.postalCode
            data.city = "aqkwsi"

            var res = await axios.put(BASE_URL + '/partners/' + userData.partnerId, { ...data },{
                headers: {
                  "Content-Type": "application/json",
                  "mode": "cors",
                  "Authorization": login.accessToken
                }
              });
            if (res.status == 200) {
                //notifySuccess();
                setSuccessMsg(true);
                window.scrollTo(0, 0);
              //  setTimeout(() => navigate('/partners/partners-list'), 10000)

            }
        } catch (e) {
            notifyErr();
        }
        if (ref.current)
            ref.current.complete()
    }



    return (
        <div>

            <div className='create-partner-form-main'>

                <LoadingBar color='#3072bb' ref={ref} height={7} />
                <ModalAlert open={successMsg} setOpen={setSuccessMsg} msgType={"Success"} onSuccess={()=>navigate('/partners/partners-list')} action="Ok" color="success">
                    Partner updated Successfully!
                </ModalAlert>
                <div className='create-partner-form'>
                    <div className='form-heading-label'>
                        <h1><i className="fas fa-edit" style={{ marginRight: "20px", }}></i>{t('headerEdit')}</h1>
                        <Link to="/partners/partners-list"> <Button className="page-btn">{t('right-nav-btn')} <i className='fas fa-eye'></i></Button> </Link>

                    </div>
                    <div className='form-body-row'>
                        {/* <div className='img-col'>
                 <i className="fas fa-user-plus" style={{ marginRight: "0px", }}/>
                 <h4>Create new Partner</h4>
                 </div> */}
                        <PartnerCreateForm formik={formik} />
                    </div>
                    <div className='form-bottom'>
                        <Button className={"page-btn " + (Object.keys(formik.errors).length > 0 ? 'disabled-btn' : '')} disabled={Object.keys(formik.errors).length > 0} onClick={formik.handleSubmit}>Update <i className='fas fa-edit'></i></Button>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}

export { EditPartner }