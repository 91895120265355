import React from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import Select from 'react-select'
import { getUserData } from '../utils'
const InvoiceDetailsModal = ({ isOpen, setIsOpen, data, downloadFile, changeStatus }) => {



    const userData = getUserData();


    return (
        <Modal isOpen={isOpen} className="table-details-modal">
            <ModalHeader>
                <span style={{fontSize:"20px"}}>Details Overview</span>
                <Button onClick={()=>setIsOpen(false)} style={{marginLeft:"auto",float:"right"}} className='action-btn'>
                    <i className='fas fa-times'></i>
                </Button>
            </ModalHeader>

            <ModalBody>
                <div className='table-details-main'>
                    {data && Object.keys(data).map(val => {
                        if(typeof data[val]=='object')
                            return null;

                        if(val=="document" || val=="timesheet" || val=="email"){
                           
                           
                           return(
                            <div className='table-details-row'>
                                <div><b>{val}</b></div>
                                <div> <Button onClick={()=>downloadFile(data[val])} >{data[val]} <i className='fas fa-download' style={{marginLeft:"13px"}}></i></Button></div>
                            </div>
                           )
                           

                        }

                        if(val=="status"){
                            return (
                                <div className='table-details-row'>
                                <div><b>{val}</b></div>
                                <div style={{ display: "flex" }} className='tbl-action-col status-drpdwn' id={'status-drpdwn-'+data.id}>

                                {(data.status != "COMPLETED" && userData && userData.role == "ADMIN") ? <Select  menuPosition="fixed" menuPlacement="auto" className='form-drpdwn1'
                            
                                  options={[
                                    {
                                      label: "CREATED",
                                      value: "CREATED"
                            
                                    }
                                    ,
                                    {
                                      label: "COMPLETED",
                                      value: "COMPLETED"
                                    },
                                    {
                                      label: "PENDING",
                                      value: "PENDING"
                                    },
                                    {
                                      label: "VOID",
                                      value: "VOID"
                                    },
                                    {
                                      label: "ACCEPTED",
                                      value: "ACCEPTED"
                                    }
                                  ]}
                                  onChange={(opt) => {
                                    changeStatus(opt.value, data)
                                  }}
                                  // value={{
                                  //     label:row.row.original.status
                                  // }}
                                  placeholder={data.status}
                            
                                /> : <div>{data.status}</div>}
                              </div>
                              </div>
                            )
                        }

                        
                        return (
                            <div className='table-details-row'>
                                <div><b>{val}</b></div>
                                <div>{data[val]}</div>
                            </div>
                        )
                    })}

                    {/* <div className='table-details-row'></div>
        <div className='table-details-row'></div>
        <div className='table-details-row'></div>
        <div className='table-details-row'></div>
        <div className='table-details-row'></div> */}

                </div>
            </ModalBody>
        </Modal>
    )
}

export default InvoiceDetailsModal