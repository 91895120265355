import React, { useReducer } from 'react'
import { User } from 'react-feather'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import './css/components.css'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toggleSidebarMobile } from '../redux/actions/sidebar'
import { getUserData, logout } from '../utils'
import { changeGlobalLanguage } from '../redux/actions/lang'
const Navbar = () => {

  const dispatch = useDispatch();
  let isSidebarCollapsed = useSelector(state => state.sidebarCollapseReducerMobile)
  const userData = getUserData();
  const navigate = useNavigate();
  const location = useLocation();
  var curLang = useSelector(state=>state.langReducer)

  return (
    <div className='navbar'>
      <div className='nav-left-menu'>
        <i className={isSidebarCollapsed ? 'fas fa-times' : 'fas fa-bars menu-mobile'} style={{ color: "#fff", fontSize: "22px", marginLeft: "10px" }} onClick={() => { dispatch(toggleSidebarMobile(!isSidebarCollapsed)) }}></i>
        {(location.pathname.includes('/partners/create-partner') || location.pathname.includes('/partners/edit-partner') || location.pathname.includes('/partners/create-purchase-order') || location.pathname.includes('/partners/edit-purchase-order') || location.pathname.includes('/invoices/create-invoice')) && <div className='action-btn back-btn' onClick={() => navigate(-1)}>
          <i class="fas fa-sign-out-alt"></i>
          <span>Prev page</span>
        </div>}
      </div>

      <div>
        <UncontrolledDropdown className='desktop-view'>
          <DropdownToggle className='language-dropdown' caret>
            <i class="bi bi-globe"></i> <span><b>{curLang}</b></span>

          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={()=>dispatch(changeGlobalLanguage('EN'))}  >EN</DropdownItem>

            <DropdownItem onClick={()=>dispatch(changeGlobalLanguage('NL'))}  >NL</DropdownItem>

          </DropdownMenu>
        </UncontrolledDropdown>


        <UncontrolledDropdown>
          <DropdownToggle className='profile-dropdown' caret>
            <User className='nav-profile-sect' size={40} />
            <span className='ml-3 mobile-hide' style={{ color: "#fff", fontSize: "14px" }}><b>{userData ? (userData.role == "ADMIN" ? " Hello Admin" : <span >{userData.userName}</span>) : ""}</b> <i className="fas fa-caret-down"></i></span><br />
            <span className='ml-3 role-lbl mobile-hide' style={{ color: "#fff",marginLeft:"auto",width:"100%" }}><b>{userData && userData.role}</b> </span>

          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem style={{ borderBottom: "1px solid #ededed", paddingLeft: "10px" }} header><span style={{ color: "#0064ff" }}>Email: </span>{userData && userData.userName}</DropdownItem>

            <a href='/auth/login' onClick={() => { localStorage.setItem("loginDetails", null); logout(); }}><DropdownItem ><i className="fas fa-sign-out-alt" style={{ marginRight: "10px" }}></i>Logout</DropdownItem></a>
            <Link  to='/auth/forgot-password'><DropdownItem ><i className="fas fa-key" style={{ marginRight: "10px" }}></i>Change Password</DropdownItem></Link>

            <span className='mobile-view'>
            <UncontrolledDropdown>
          <DropdownToggle className='language-dropdown' caret>
            <i class="bi bi-globe"></i>
             <span><b>{curLang}</b></span>

          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={()=>dispatch(changeGlobalLanguage('EN'))}  >EN</DropdownItem>

            <DropdownItem onClick={()=>dispatch(changeGlobalLanguage('NL'))}  >NL</DropdownItem>

          </DropdownMenu>
        </UncontrolledDropdown>
            </span>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </div>
  )
}

export default Navbar