const initialState = 'EN';

const langReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_LANG_CHANGE': return action.val;
        default: return state;
    }
}



export default langReducer;
